$primary: rgba(0, 113, 225, 1);

$active-color: $primary;

$border-color: #E3EBF6;

$gradient-color1: rgba(0, 113, 225, 0.9);
$gradient-color2: rgba(0, 196, 255, 0.9);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

$sidebar-width: 250px;
$sidebar-width-collapsed: 93px;

$sidebar-transition-speed: 0.3s;
$sidebar-transition-curve: cubic-bezier(.93,0,.22,1);
$sidebar-overlay-curve: ease;

@import "~easymde/dist/easymde.min.css";

@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "~@zandor300/backoffice-framework/lib/scss/backoffice-framework";

@import "animation";
@import "bank-card";
@import "bottom-tabbar";
@import "button";
@import "energy-flow-card";
@import "energy-handling-chart";
@import "installation-detail-overview";
@import "live-indicator";
@import "math";
@import "modal";
@import "table";
@import "tooltip";

.login-background {
  background-image: linear-gradient(to bottom right, $gradient-color1, $gradient-color2), url(./../img/solar-panels.jpg);
}
.sidebar {
  background-image: linear-gradient(to bottom right, $gradient-color1, $gradient-color2), url(./../img/solar-panels.jpg);
}

@page {
  size: auto;
}

.text-vertical {
  writing-mode: vertical-rl !important;
  text-orientation: mixed !important;
}

.card-comment {
  margin-bottom: 15px;
}

.detail-header-cell {
  text-align: right;
  width: 250px;
}

.detail-header-cell-sm {
  text-align: right;
  width: 250px;
}

.card-qrcode {
  @media print {
    display: block !important;
  }
}

.flex-print-row {
  @media print {
    flex-direction: row !important;
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.mb-0-p-last {
  p:last-child {
    margin-bottom: 0;
  }
}

.page-container {
  margin-bottom: env(safe-area-inset-bottom);
}
.sidebar {
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}
