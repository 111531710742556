.fa-spin-slow {
  animation: fa-spin-slow 10s infinite linear;
}
@keyframes fa-spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
